<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M15.04 22.39l.01 0 0 0c2.18 1.39 5.04 1.07 6.88-.76l.77-.78 -.01 0c.71-.73.71-1.89 0-2.61l-3.27-3.26 0 0c-.73-.72-1.89-.72-2.61-.01v0l-.01 0c-.72.72-1.89.72-2.61 0 -.01-.01-.01-.01-.01-.01L8.96 9.74l0 0c-.72-.73-.72-1.89-.01-2.61v0l0-.01c.72-.72.72-1.89 0-2.61 -.01-.01-.01-.01-.01-.01L5.68 1.23l0 0C4.95.51 3.79.51 3.07 1.22l-.78.77 -.01 0C.44 3.82.13 6.68 1.52 8.87l0 .01 -.01-.01c3.57 5.28 8.12 9.84 13.41 13.41Z"
    />
  </svg>
</template>
